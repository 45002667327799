<template functional>
  <component
    :is="props.tag"
    :id="data.id"
    :class="['aspectratio', `ratio-${props.ratio}`, data.class, data.staticClass]"
    :style="props.enableStyles ? data.style : ''"
    v-bind="data.attrs"
  >
    <span>
      <slot />
    </span>
  </component>
</template>

<script>
export default {
  name: 'AspectRatio',
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    ratio: { // valid values: 3x4, 1x1, 4x3, 2x1
      type: String,
      required: true
    },
    enableStyles: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss">
.aspectratio {
  display: block;
  position: relative;
  width: 100%;

  &:before {
    display: block;
    content: '';
  }

  @each $key, $value in $aspect-ratios {
    &.ratio-#{$key} {
      aspect-ratio: $value;
    }
    &.ratio-#{$key}:before {
      padding-top: 100% * math.div(1, $value);
    }
  }

  > span {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
