<template>
  <c-info-container class="basket-deliverytype">
    <h6 class="mb-0">{{ $t(`${tPath}.title`) }}</h6>
    <hr class="mb-3" />

    <b-form-radio-group v-model="value" :options="options" @change="setDeliveryType" />
  </c-info-container>
</template>

<script>
export default {
  name: 'BasketDeliveryType',
  props: {
    checked: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      value: null
    }
  },
  computed: {
    is() {
      return this.$store.getters['shoppingcart/is']
    },
    type() {
      return this.$store.getters['shoppingcart/getType']
    },
    configuration() {
      return this.$store.getters['shoppingcart/getConfiguration']
    }
  },
  methods: {
    setDeliveryType(deliveryType) {
      this.$store.dispatch('shoppingcart/update', Object.assign({}, this.$store.getters['shoppingcart/getConfiguration'], { deliveryType }))
    }
  },
  created() {
    if (this.type !== 'full') this.$store.dispatch('shoppingcart/get')
  },
  watch: {
    configuration: {
      immediate: true,
      deep: true,
      handler() {
        const deliveryType = this.configuration.deliveryType ?? this.checked

        if (this.value !== deliveryType) {
          this.value = deliveryType
          this.setDeliveryType(this.value)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.basket-deliverytype {
}
</style>
