<template>
    <div class="store-clerk-discount">
      <b-row align-v="center" v-if="writeable">
        <b-col lg="6">
          <control-textarea
            class="discount-text mb-5 mb-lg-0"
            v-model="value.customDiscountText"
            :placeholder="$t(`${tPath}.customdiscounttextplaceholder`)"
            :validations="controls.customDiscountText.validations"
            :feedbacksInvalid="controls.customDiscountText.feedbacksInvalid"
            ref="CustomDiscountText"
            @change="updateDiscount"
          />
        </b-col>
        <b-col lg="6">
          <div class="discount-options pl-lg-5">
            <b-form-group class="control-input m-0">
              <b-input-group>
                <b-input v-model="value.customDiscountValue" @blur="updateDiscount" @focus="$event.target.select()" debounce="500"></b-input>
                <b-input-group-append>
                  <b-form-radio-group
                    :options="discountTypeOptions"
                    v-model="value.customDiscountType"
                    @change="updateDiscount"
                    button-variant="outline-primary"
                    buttons
                  ></b-form-radio-group>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <b-row v-else class="read-only-discount no-gutters">
        <b-col lg="6">
          <p>{{ value.customDiscountText }}</p>
        </b-col>
        <b-col lg="6" class="discount-value text-right">
          <c-product-price v-if="value.customDiscountType === 'ValueDiscount'" :productId="'HU-DISCOUNT'" :priceinfo="readOnlyPriceInfo" :configuration="{}" :quantity="1" alginH="end" hideVatInfo inherit/>
          <div v-else>
            <span class="percent-value">{{ -value.customDiscountValue }}</span>
            <span class="percent-text">{{ selectedDiscountText }}</span>
          </div>
        </b-col>
      </b-row>
    </div>
</template>

<script>
import ControlTextarea from '@/components/private/forms/ControlTextarea'

export default {
  name: 'StoreClerkDiscount',
  components: {
    ControlTextarea
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    itemId: {
      type: String,
      required: true
    },
    writeable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      controls: {
        customDiscountText: {
          validations: {
            required: this.$root.validators.required
          },
          feedbacksInvalid: {
            required: this.$t(`${this.tPath}.customdiscounttextfeedbacks.required`)
          }
        }
      },
      discountTypeOptions: [
        { value: 'ValueDiscount', text: this.$t(`${this.tPath}.valuediscountpostfix`) },
        { value: 'PercentDiscount', text: this.$t(`${this.tPath}.percentdiscountpostfix`) }
      ]
    }
  },
  computed: {
    selectedDiscountText () {
      const activeType = this.discountTypeOptions.find(x => x.value === this.value.customDiscountType)
      return activeType.text || ''
    },
    readOnlyPriceInfo () {
      return {
        price: -this.value.customDiscountValue,
        currency: this.selectedDiscountText,
        isPromotionalPrice: false,
        minimumQuantity: 0,
        includeVAT: true
      }
    }
  },
  methods: {
    updateDiscount () {
      if (this.$refs.CustomDiscountText.$v.$invalid) {
        this.$refs.CustomDiscountText.$v.$touch()
      } else {
        this.$emit('change', this.value)
      }
    }
  },
  watch: {
    'value.customDiscountValue' (newVal, oldVal) {
      if (oldVal !== newVal && !/\.$/.test(newVal)) {
        this.updateDiscount()
      }
    }
  }
}
</script>

<style lang="scss">
.store-clerk-discount {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  .discount-text{
    margin-bottom: 0;
  }

  .discount-options {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;

    .form-control {
      text-align: right;
    }
  }

  .read-only-discount {
    width: 100%;

    .discount-value {
      font-weight: $font-weight-bold;
    }
  }
}
</style>
