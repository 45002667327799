<template>
  <div class="product-properties" v-if="haveRelevantProperties">
    <div :class="`product-property ${prop.configuration.cssClass}`" v-for="prop in relevantProperties" :key="prop.key">
      <div :id="`Popover_${_uid}`" class="alert alert-info">
        <lazy-icon :icon="prop.configuration.icon" class="mr-2" />
        {{ prop.title }}
      </div>
      <b-popover :target="`Popover_${_uid}`" placement="top" triggers="hover" v-if="prop.explanation">
        {{ prop.explanation }}
      </b-popover>
    </div>
  </div>
</template>

<script>

const relevantProductProperties = ['prod_mithilfeablad']
const propertyToIconMap = {
  prod_mithilfeablad: {
    icon: 'b-info-circle',
    cssClass: 'font-sm',
    iconVariant: 'warning'
  }
}

export default {
  name: 'ProductProperties',
  props: {
    product: {
      type: Object,
      default: () => ({ properties: {} })
    }
  },
  computed: {
    haveRelevantProperties() {
      return Object.keys(this.product.properties).some(prop => relevantProductProperties.includes(prop))
    },
    relevantProperties() {
      return Object.keys(this.product.properties)
        .filter(prop => relevantProductProperties.includes(prop))
        .map(prop => ({
          key: prop,
          title: this.$t(`${this.tPath}.properties.${prop.replace('_', '')}.title`),
          explanation: this.$t(`${this.tPath}.properties.${prop.replace('_', '')}.explanation`),
          configuration: propertyToIconMap[prop]
        }))
    }
  }
}
</script>

<style lang="scss">
.product-properties {
  display: flex;
  align-items: center;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}
</style>
