<template>
  <div class="product-deliverystate">
    <lazy-icon v-if="isDiscontinued && isStoreClerk" icon="b-exclamation-circle" variant="danger" :title="$t(`${tPath}.discontinued`)" />
    <template v-if="popover">
      <lazy-icon :id="`Popover_${_uid}`" :icon="state.icon" :variant="state.variant" />
      <b-popover :target="`Popover_${_uid}`" placement="top" triggers="hover" custom-class="delivery-state-popover">
        <lazy-icon :icon="state.icon" :variant="state.variant" /> {{ stateText }}
      </b-popover>
    </template>

    <template v-else>
      <lazy-icon v-if="state.icon" :icon="state.icon" :variant="state.variant" /><span>{{ stateText }}<slot></slot></span>
    </template>
  </div>
</template>

<script>
import { COMPONENT_PRODUCTDELIVERYSTATE_STATES, PRODUCTDELIVERYSTATE_COMPONENT_STATES } from '@/constants';

export default {
  name: 'ProductDeliveryState',
  props: {
    stateId: {
      type: String,
      default: COMPONENT_PRODUCTDELIVERYSTATE_STATES.unknown.serverId
    },
    stockAmount: {
      type: Number,
      default: 0
    },
    restockTime: {
      type: String,
      default: ''
    },
    popover: {
      type: Boolean,
      default: false
    },
    isDiscontinued: {
      type: Boolean,
      default: false
    },
    isStoreClerk: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isRestockState() {
      return this.stateId === COMPONENT_PRODUCTDELIVERYSTATE_STATES.inweeks.serverId || this.stateId === COMPONENT_PRODUCTDELIVERYSTATE_STATES.in6to8weeks.serverId
    },
    state() {
      return PRODUCTDELIVERYSTATE_COMPONENT_STATES[this.stateId] || COMPONENT_PRODUCTDELIVERYSTATE_STATES.unknown
    },
    stateText() {
      if (this.isRestockState) {
        const restockSplit = /^(\d+)([A-Za-z]+)$/
        const restockValueMatch = this.restockTime.match(restockSplit)
        if (restockValueMatch) {
          const restockUnit = this.$t(`${this.tPath}.restockunit.${restockValueMatch[2].toLowerCase()}`)
          const restockTime = restockValueMatch[1]
          if (restockTime > 0 && restockUnit && restockUnit.length > 0) {
            return this.$t(`${this.tPath}.inexact`, { restockTime, restockUnit })
          }
        }
      }

      return this.$t(`${this.tPath}.${(this.state.serverId || '').toLowerCase()}`, { stockAmount: this.stockAmount })
    }
  }
}
</script>

<style lang="scss">
$productdeliverystate-gap-y: $spacer * 0.4 !default;
$productdeliverystate-font-size: $font-size-sm !default;
$productdeliverystate-color: $gray-700 !default;
$productdeliverystate-line-height: 1.2 !default;

$productdeliverystate-icon-gap: $spacer * 0.6 !default;
$productdeliverystate-icon-size: $font-size-base !default;

.product-deliverystate {
  display: flex;
  align-items: center;
  margin-top: $productdeliverystate-gap-y;
  margin-bottom: $productdeliverystate-gap-y;
  font-size: $productdeliverystate-font-size;
  color: $productdeliverystate-color;
  line-height: $productdeliverystate-line-height;

  .bi {
    display: block;
    margin-top: math.div($productdeliverystate-line-height - 1, 2) * -1em;
    margin-right: $productdeliverystate-icon-gap;
    font-size: $productdeliverystate-icon-size;
  }
}

.delivery-state-popover {
  .popover-body {
    color: $productdeliverystate-color;
  }
}
</style>
