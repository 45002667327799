<template>
  <div class="item-characteristics">
    <span v-for="property, idx in characteristics" :key="`${idx}_${property.name}`">
      <template v-if="property.type === 'default'">
        <template v-if="property.name">{{ property.name }}: </template>{{ property.value }}
      </template>

      <product-color v-else-if="property.type === 'color'" colorProperty="additionalInfo" textProperty="value" :item="property" compact/>
    </span>
  </div>
</template>

<script>
import ProductColor from '@/components/private/ProductColor'
const FAMILY_GIFTCARD = 49

export default {
  name: 'ItemCharacteristics',
  components: {
    ProductColor
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    characteristics () {
      return [
        // Elmiminates duplicates by name
        ...new Map([]
          .concat(
            this.item.product.characteristics ?? [],
            { type: 'default', value: this.getAssemblyLabel() },
            { type: 'default', value: this.item.product.id }
          )
          .filter(c => c.value)
          .map(c => [`${c.name}_${c.value}`, c])
        ).values()
      ]
    }
  },
  methods: {
    getAssemblyLabel () {
      if (this.item.configuration.assemblyType) {
        let translationKey = `${this.tPath}.characteristics.`
        if (this.item.product.familyId === FAMILY_GIFTCARD) {
          switch (this.item.configuration.assemblyType.toLowerCase()) {
            case 'assembled':
              translationKey += 'giftwrapped'
              break
            case 'unassembled':
              translationKey += 'notgiftwrapped'
              break
          }
        } else {
          translationKey += this.item.configuration.assemblyType.toLowerCase()
        }

        return this.$t(translationKey)
      }

      return ''
    }
  }
}
</script>

<style lang="scss">
$itemcharacteristics-item-gap-y: 0 !default;
$itemcharacteristics-item-gap-x: $spacer * 1.2 !default;

.item-characteristics {
  display: flex;
  flex-wrap: wrap;
  margin: ($itemcharacteristics-item-gap-y * -0.5) ($itemcharacteristics-item-gap-x * -0.5);

  > span {
    display: block;
    padding: ($itemcharacteristics-item-gap-y * 0.5) ($itemcharacteristics-item-gap-x * 0.5);
  }
}
</style>
