<template>
  <reseller-form
    class="basket-reselleroptions"
    :controls="controls"
    eventsOnly
    @form:change="onChange"
    @form:submit="onChange"
    v-slot="formSlotProps"
    >
    <slot v-bind="formSlotProps"/>
  </reseller-form>
</template>

<script>
import ResellerForm from '@/components/public/forms/Form'

export default {
  name: 'BasketResellerOptions',
  components: {
    ResellerForm
  },
  props: {
    controls: {
      type: Object,
      default: () => ({})
    },
    initialOptionValues: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      optionsActive: this.initialOptionValues,
      optionsData: {}
    }
  },
  computed: {
    is () {
      return this.$store.getters['shoppingcart/is']
    },
    type () {
      return this.$store.getters['shoppingcart/getType']
    },
    configuration () {
      return this.$store.getters['shoppingcart/getConfiguration']
    }
  },
  methods: {
    onChange (form = null) {
      this.optionsActive = form.formData
      this.$store.dispatch('shoppingcart/update', this.optionsActive)
    }
  },
  created () {
    if (this.type !== 'full') this.$store.dispatch('shoppingcart/get')
  }
}
</script>

<style lang="scss">
.basket-deliverytype {

}
</style>
